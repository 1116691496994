<template>
  <div>
    <!-- Start Header Area -->
    <Header>
        <img slot="logo" src="../../assets/images/logo/logo-symbol-light.png"/>
    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area  -->
    <div
      class="slide slide-style-2 slider-video-bg d-flex align-center justify-center overlay-fix"
      data-black-overlay="6"
    >
      <v-container>
        <v-row align="center">
          <v-col md="8" sm="12" cols="12">
            <div class="inner text-left">
              <h1 class="heading-title">Metoda<br>Feldenkraisa</h1>
              <p class="description">
                  Rozwój samoświadomości związanej z ruchem ciała - położeniem części ciała względem siebie, stopniem napięcia mięśni - prowadzi do poprawy samopoczucia, zwiększenia dobrostanu i zmniejszenia dolegliwości bólowych.
              </p>
            </div>
          </v-col>
          <v-col
            md="4"
            sm="12"
            cols="12"
            v-for="(image, imageIndex) in items"
            :key="imageIndex"
          >
            <div class="video-inner mt_sm--30 mt_md--30">
              <a
                @click="index = imageIndex"
                class="video-popup theme-color play__btn"
                ><span class="play-icon"></span
              ></a>
            </div>
            <CoolLightBox
              :items="items"
              :index="index"
              @close="index = null"
              fullScreen
            >
            </CoolLightBox>
          </v-col>
        </v-row>
      </v-container>

      <div class="video-background">
        <span>
          <video muted="" autoplay="" playsinline="" loop="" poster="">
            <source src="../../assets/images/service/video.mp4" />
          </video>
        </span>
      </div>
    </div>
    <!-- End Slider Area  -->

    <!-- Start Blog Area  -->
    <div class="rn-blog-area rn-section-gapTop bg_color--1">
      <v-container>
        <v-row align="end" class="mb--20">
          <v-col lg="6" md="6" sm="12" cols="12">
            <div class="section-title text-left">
              <h2>Najnowsze artykuły</h2>
              <p>
                Ostatnie wieści dotyczące Metody w Polsce
              </p>
            </div>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12">
            <div class="blog-btn text-left text-md-right mt_sm--10 mt_md--10">
              <a class="btn-transparent rn-btn-dark" href="/blog">
                  <span class="text">Zobacz wszystkie artykuły</span>
              </a>
            </div>
          </v-col>
        </v-row>
        <Blog />
      </v-container>
    </div>
    <!-- End Blog Area  -->

    <!-- Start Brand Area -->
    <div class="rn-brand-area brand-separation">
      <v-container>
        <v-row>
          <v-col cols="12">
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Brand Area -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
  import Header from "../../components/header/Header";
  import Blog from "../../components/blog/Blog";
  import Footer from "../../components/footer/Footer";

  export default {
    components: {
      Header,
      Blog,
      Footer
    },
    data() {
      return {
        items: [
          {
            src: "https://www.youtube.com/watch?v=OLQiDfTbEb0",
          },
        ],
        index: null,
      };
    },
  };
</script>
