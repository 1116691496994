<template>
  <v-row>
    <!-- Start Blog Area  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(item, i) in blogContent"
      :key="i"
    >
      <div class="blog blog-style--1">
        <div class="thumbnail">
          <router-link :to="blogLink(item.slug)">
            <img class="w-100" :src="item.thumbnail" :alt="item.title" />
          </router-link>
        </div>
        <div class="content">
          <p class="blogtype">{{ formatCreatedAt(item.createdAt) }}</p>
          <h4 class="heading-title">
            <router-link :to="blogLink(item.slug)">{{ item.title }}</router-link>
          </h4>
          <div class="blog-btn">
            <router-link :to="blogLink(item.slug)" class="rn-btn white--text">
              Więcej
            </router-link>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Blog Area  -->
  </v-row>
</template>

<script>
  import axios from 'axios';

  export default {
    data() {
      return {
        blogContent: [],
      };
    },
      methods: {
          blogLink(slug) {
              return 'blog/' + slug;
          },
          formatCreatedAt(createdAt) {
              return (new Date(parseInt(createdAt) * 1000)).toLocaleDateString("pl-PL");
          }
      },
      mounted() {
          axios({
              url: process.env.VUE_APP_API_ENDPOINT,
              method: 'post',
              data: {
                  query: `
                        query getAllNews {
                            articles(limit: 3) {
                                slug,
                                title,
                                thumbnail,
                                createdAt
                            }
                        }
                    `
              }
          })
          .then(response => {
              this.blogContent = response.data.data.articles;
          })
      },
  };
</script>
