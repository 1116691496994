<template>
  <div>
    <v-navigation-drawer
      class="hidden-md-and-up"
      v-model="drawer"
      absolute
      temporary
      width="320"
    >
      <v-list-item class="pa-5">
        <div class="logo">
          <img
            src="../../assets/images/logo/logo-symbol-light.png"
            alt="Feldenkrais Polska"
          />
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="close-icon"
          icon
          @click="drawer = !drawer"
          v-html="iconSvg(closeIcon)"
        >
        </v-btn>
      </v-list-item>

      <v-list>
        <v-list-item
          :ripple="false"
          v-for="item in MobileMainMenuItems"
          :key="item.title"
          :to="item.to"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- End mobile menu sidebar item list -->
      </v-list>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
      dark
      elevation="0"
      color="transparent"
      absolute
      class="default-header"
    >
      <router-link to="/" class="logo">
        <slot name="logo"></slot>
      </router-link>
      <!-- End brand logo -->
      <v-spacer></v-spacer>
      <v-btn
        icon
        class="ma-0 pa-0 hidden-md-and-up"
        @click="drawer = !drawer"
        v-html="iconSvg(icon)"
      >
      </v-btn>
      <!-- End mobile menu icon -->
      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto">
      <v-btn :ripple="false" text to="/">Strona główna</v-btn>
      <v-menu
          open-on-hover
          bottom
          min-width="240"
          offset-y
          transition="scroll-y-reverse-transition"
      >
          <template v-slot:activator="{ on, attrs }">
              <v-btn :ripple="false" text v-bind="attrs" v-on="on">
                  O metodzie
              </v-btn>
          </template>

          <v-list>
              <v-list-item
                  link
                  v-for="(item, index) in AboutItems"
                  :key="index"
                  :to="item.to"
              >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
          </v-list>
        </v-menu>
        <v-btn :ripple="false" text to="/blog">Artykuły</v-btn>
        <v-btn :ripple="false" text to="/nauczyciele">Nauczyciele</v-btn>
        <v-btn :ripple="false" text to="/faq">F.A.Q.</v-btn>
<!--        <v-btn :ripple="false" text to="/kontakt">Kontakt</v-btn>-->
        <v-picker />

<!--        <v-menu-->
<!--          open-on-hover-->
<!--          bottom-->
<!--          min-width="240"-->
<!--          offset-y-->
<!--          transition="scroll-y-reverse-transition"-->
<!--        >-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <v-btn :ripple="false" text v-bind="attrs" v-on="on">-->
<!--              Examples-->
<!--            </v-btn>-->
<!--          </template>-->

<!--          <v-list>-->
<!--            <v-list-item-->
<!--              link-->
<!--              v-for="(item, index) in ExamplesDropDownItems"-->
<!--              :key="index"-->
<!--              :to="item.to"-->
<!--            >-->
<!--              <v-list-item-title>{{ item.title }}</v-list-item-title>-->
<!--            </v-list-item>-->
<!--          </v-list>-->
<!--        </v-menu>-->

      </v-toolbar-items>
      <!-- End header menu item -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  data: () => ({
    drawer: false,
    MobileMainMenuItems: [
        { title: "Strona główna", to: "/" },
        { title: "O Metodzie Feldenkrais", to: "/metoda" },
        { title: "Artykuły", to: "/blog" },
        { title: "O Mosche Feldenkrais", to: "/moshe-feldenkrais" },
        { title: "Standardy praktyki", to: "/standardy-praktyki" },
        { title: "Nauczyciele", to: "/nauczyciele" },
        { title: "Materiały", to: "/materialy" },
        { title: "F.A.Q.", to: "/faq" },
        // { title: "Kontakt", to: "/contact" },
    ],
    AboutItems: [
        { title: "O Metodzie Feldenkrais", to: "/metoda" },
        { title: "O Mosche Feldenkrais", to: "/moshe-feldenkrais" },
        { title: "Standardy praktyki", to: "/standardy-praktyki" },
        { title: "Materiały", to: "/materialy" },
    ],
    ExamplesDropDownItems: [
      { title: "Home Main Demo", to: "/main-demo" },
      { title: "Home Main Demo Dark", to: "/main-demo-dark" },
      { title: "Home Creative Agency", to: "/creative-agency" },
      { title: "Home Creative Agency Landing", to: "/landing-creative-agency" },
      { title: "Home Personal Portfolio", to: "/personal-portfolio" },
      { title: "Home Personal Portfolio 02", to: "landing-personal-portfolio-02" },
      {
        title: "Home Personal Portfolio Landing",
        to: "/landing-personal-portfolio",
      },
      { title: "Home Designer Portfolio", to: "/designer-portfolio" },
      { title: "Home Creative Portfolio", to: "/creative-portfolio" },
      { title: "Home Business", to: "/business" },
      { title: "Home Digital Agency", to: "/digital-agency" },
      { title: "Home Minimal Portfolio", to: "/minimal-portfolio" },
      { title: "Home Studio Agency", to: "/studio-agency" },
      { title: "Home Home Particles", to: "/landing-home-particle" },
      { title: "Home Startup", to: "/startup" },
      { title: "Home Home Paralax", to: "/parallax-home" },
      { title: "Home Corporate Business", to: "/corporate-business" },
      { title: "Home Interactive Agency", to: "/interactive-agency" },
      { title: "Service", to: "/service" },
      { title: "Service Details", to: "/service-details" },
      { title: "Pages Blog List", to: "/blog" },
      { title: "Pages Blog Details", to: "/blog-details" },
      { title: "Pages Service", to: "/service" },
      { title: "Pages Service Details", to: "/service-details" },
      { title: "Pages Portfolio", to: "/portfolio" },
      { title: "Pages Portfolio Details", to: "/portfolio-details" },
      { title: "Pages 404 Page", to: "/404" },
      { title: "Blocks Portfolio", to: "/portfolio" },
      { title: "Blocks Team", to: "/team" },
      { title: "Blocks Service", to: "/service" },
      { title: "Blocks Video Popup", to: "/video-popup" },
      { title: "Blocks Progressbar", to: "/progressbar" },
      { title: "Blocks Gallery", to: "/gallery" },
      { title: "Blocks Counters", to: "/counter" },
      { title: "Blocks Blog List", to: "/blog" },
      { title: "Blocks Client Logo", to: "/brand" },
      { title: "Blocks Contact Form", to: "/contact-form" },
      { title: "Blocks Columns", to: "/column" },
      { title: "Blocks Button", to: "/button" },
      { title: "Blocks List Style", to: "/list-style" },
      { title: "Blocks Testimonial", to: "/testimonial" },
      { title: "Blocks Accordion", to: "/accordion-with-tab" },
      { title: "Blocks Pricing Plan", to: "/pricing-plan" },
    ],
    icon: "menu",
    closeIcon: "x",
  }),

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>

<style lang="scss">
.feather-menu {
  color: #c6c9d8;
}
</style>
